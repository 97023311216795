import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useGlobalAudioPlayer } from "react-use-audio-player";
import { set } from "lodash";

export const AudioProviderContext = React.createContext({
    play: () => null,
    stop: () => null,
    isPlaying: false,
    loadAudio: () => console.log("Audio not loaded"),
    unloadAudio: () => null,
    queueAudioToPlay: () => null,
    playingAudioUrl: null,
});

export default function AudioProvider({ children }) {
    const [isPlaying, setPlaying] = useState();
    const { languageSettings } = useAuth();
    const history = useHistory();
    const [playQueue, setPlayQueue] = useState([]);
    const isPlayingRef = useRef(isPlaying);
    const [playingAudioUrl, setPlayingAudioUrl] = useState();
    const { load, stop: internalStop, pause: internalPause } = useGlobalAudioPlayer();

    function unloadAudio() {
        internalPause();
    }

    useEffect(() => {
        history.listen((location, action) => {
            stop();
        });
    }, []);

    function stop() {
        setPlaying(false);
        setPlayQueue([]);
        internalStop();
    }

    function queueAudioToPlay(url, clearQueue = false, playbackRate = 1.0) {
        console.log("Queueing audio to play", url, clearQueue, playbackRate);
        if (languageSettings.audio_enabled === false) {
            return;
        }

        if (clearQueue) {
            stop();
            setPlayQueue([[url, playbackRate]]);
        } else {
            setPlayQueue([...playQueue, [url, playbackRate]]);
        }
    }

    useEffect(() => {
        if (playQueue.length > 0 && !isPlaying) {
            play(playQueue[0][0], playQueue[0][1]);
            setPlayQueue(playQueue.slice(1));
        }
    }, [playQueue, isPlaying]);

    useEffect(() => {
        isPlayingRef.current = isPlaying;
    }, [isPlaying]);

    async function play(url, playbackRate = 1.0) {
        load(url, {
            playbackRate,
            format: "mp3",
            html5: true,
            autoplay: true,
            onplay: () => {
                setPlaying(true);
            },
            onend: () => {
                setPlaying(false);
            },
            onpause: () => {
                setPlaying(false);
            },
        });
        setPlayingAudioUrl(url);
        setPlaying(true);

        return url;
    }

    const contextValue = {
        play,
        stop,
        isPlaying,
        loadAudio: () => null,
        unloadAudio,
        queueAudioToPlay,
        playingAudioUrl,
    };

    return <AudioProviderContext.Provider value={contextValue}>{children}</AudioProviderContext.Provider>;
}
