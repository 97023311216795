import LocalizedStrings from "react-localization";

import en_i18n from "../i18n/en.json";
import fr_i18n from "../i18n/fr.json";
import es_mx_i18n from "../i18n/es-mx.json";
import es_es_i18n from "../i18n/es-es.json";
import de_i18n from "../i18n/de.json";
import it_i18n from "../i18n/it.json";
import ru_i18n from "../i18n/ru.json";
import zh_tw_i18n from "../i18n/zh-tw.json";

import fr_generated_i18n from "../i18n/generated/fr.json";
import es_mx_generated_i18n from "../i18n/generated/es-mx.json";
import es_es_generated_i18n from "../i18n/generated/es-es.json";
import de_generated_i18n from "../i18n/generated/de.json";
import it_generated_i18n from "../i18n/generated/it.json";
import pt_br_generated_i18n from "../i18n/generated/pt-br.json";
import pt_pt_generated_i18n from "../i18n/generated/pt-pt.json";
import uk_generated_i18n from "../i18n/generated/uk.json";
import ru_generated_i18n from "../i18n/generated/ru.json";
import zh_tw_generated_i18n from "../i18n/generated/zh-tw.json";

export const allStrings = {
    en: en_i18n,
    fr: fr_i18n,
    "es-mx": es_mx_i18n,
    "es-es": es_es_i18n,
    de: de_i18n,
    it: it_i18n,
    "pt-br": {},
    "pt-pt": {},
    uk: {},
    ru: ru_i18n,
    "zh-tw": zh_tw_i18n,
};

function add_generated_i18n(existing, generated_i18n) {
    for (let key of Object.entries(generated_i18n)) {
        if (!existing[key[0]]) {
            existing[key[0]] = key[1];
        }
    }
}

add_generated_i18n(allStrings.fr, fr_generated_i18n);
add_generated_i18n(allStrings["es-es"], es_es_generated_i18n);
add_generated_i18n(allStrings["es-mx"], es_mx_generated_i18n);
add_generated_i18n(allStrings.de, de_generated_i18n);
add_generated_i18n(allStrings.it, it_generated_i18n);
add_generated_i18n(allStrings["pt-br"], pt_br_generated_i18n);
add_generated_i18n(allStrings["pt-pt"], pt_pt_generated_i18n);
add_generated_i18n(allStrings.ru, ru_generated_i18n);
add_generated_i18n(allStrings.uk, uk_generated_i18n);
add_generated_i18n(allStrings["zh-tw"], zh_tw_generated_i18n);

export const strings = new LocalizedStrings(allStrings);
