import { Airplane, AirplaneTakeoff, ArrowLeft, List } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Container } from "semantic-ui-react";
import useAuth from "../hooks/useAuth";
import { useQuery } from "../hooks/useQuery";
import useSidebar from "../hooks/useSidebar";
import useTitle from "../hooks/useTitle";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { strings } from "../utils/i18n.utils";
import { ClickableIcon } from "./ClickableIcon";
import { Link } from "react-router-dom/cjs/react-router-dom";

export const OutsideAppNavigation = ({}) => {
    const history = useHistory();
    const { showSidebar, setShowSidebar } = useSidebar();

    return (
        <div className={"landing-page-top-bar"}>
            <div className="landing-page-logo">
                <Airplane weight="fill" className="solid-icon round black rotate90" />
                <a href="/">
                    <div>Jetway</div>
                </a>
            </div>
            <div style={{ flexGrow: 4 }}></div>
            <div>
                <Button content={strings.login} primary compact onClick={() => history.push("/login")} />
            </div>
            <div>
                <ClickableIcon icon={<List />} onClick={() => setShowSidebar(!showSidebar)} />
            </div>
        </div>
    );
};

export const TopBar = ({ fullWidth, children }) => {
    const { backPath, navigationTitle } = useTitle();
    const { user } = useAuth();
    const query = useQuery();
    const location = useLocation();
    const history = useHistory();
    const { width } = useWindowDimensions();
    const PRODUCT_PAGES = [
        "/",
        "/about",
        "/teachers",
        "/login",
        "/signup",
        "/privacy",
        "/terms",
        "/contact",
        "/overview",
    ];
    const [backButtonUrl, setBackButtonUrl] = useState(null);

    useEffect(() => {
        if (query.get("back") && query.get("back") !== "" && query.get("back") !== location.pathname) {
            setBackButtonUrl(query.get("back"));
        } else {
            setBackButtonUrl(backPath);
        }
    }, [backPath, window.query]);

    return createPortal(
        <>
            {PRODUCT_PAGES.includes(location.pathname) && <OutsideAppNavigation />}
            {(user || query.get("back")) &&
                !PRODUCT_PAGES.includes(location.pathname) &&
                (backButtonUrl || (width <= 1200 && navigationTitle && navigationTitle !== "") || children) && (
                    <Container className={fullWidth && "full-width"}>
                        <div className="topbar-wrapper">
                            {backButtonUrl && (
                                <Link to={backButtonUrl}>
                                    <ArrowLeft weight="bold" />
                                </Link>
                            )}

                            {navigationTitle && <span className="title">{navigationTitle}</span>}

                            {children}
                        </div>
                    </Container>
                )}
        </>,
        document.getElementById("topbar")
    );
};
