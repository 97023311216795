import {
    Airplane,
    AirplaneInFlight,
    AirplaneTakeoff,
    AirplaneTilt,
    ClockCounterClockwise,
    DotsThree,
    Gear,
    Hand,
    SignOut,
} from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Dropdown, Label } from "semantic-ui-react";
import useAuth from "../hooks/useAuth";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { allStrings, strings } from "../utils/i18n.utils";
import { isMobile } from "../utils/platform.utils";
import "./HomeTopBar.css";
import LanguageMenu from "./LanguageMenu";

const HomeTopBar = () => {
    const { user, currentSpace } = useAuth();
    const dimensions = useWindowDimensions();
    const [isMobileDevice, setIsMobileDevice] = useState(isMobile.any());

    useEffect(() => {
        setIsMobileDevice(dimensions.width < 1200);
    }, [dimensions]);

    return (
        <div className="home-top-bar">
            <div className="home-top-row">
                <span className="home-top-bar-greeting">
                    <Airplane weight="fill" className="solid-icon round black rotate90" />{" "}
                    {!isMobile.any() && (
                        <>
                            {allStrings[currentSpace.language].hello}, {user.name}.
                        </>
                    )}
                    {isMobile.any() && <>{allStrings[currentSpace.language].hello}</>}
                </span>
                <div class="home-top-bar-buttons">
                    <LanguageMenu />
                    <Dropdown
                        trigger={<Label basic icon={<DotsThree weight="bold" />} className="icon" />}
                        icon={null}
                        direction={"left"}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                as={"a"}
                                href={`/spaces/${currentSpace.id}/settings`}
                                icon={<Gear />}
                                content={strings.settings}
                            />
                            <Dropdown.Item
                                as={"a"}
                                href={`/spaces/${currentSpace.id}/history`}
                                icon={<ClockCounterClockwise />}
                                content={strings.history}></Dropdown.Item>
                            <Dropdown.Item
                                as={"a"}
                                href={`/logout`}
                                icon={<SignOut />}
                                content={strings.logout}></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export const HomeTopBarPortal = () => {
    return createPortal(<HomeTopBar />, document.getElementById("topbar"));
};

export default HomeTopBar;
