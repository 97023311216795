import { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import { Button, Container, Divider } from "semantic-ui-react";

import { createPortal } from "react-dom";
import { LoginCallback, LoginStandalone } from "./components/Login";
import { SelectLevelStandalone, Start } from "./screens/Start.js";
import { SettingsScreen } from "./screens/Settings.js";

import { Prompt } from "./components/Prompt";
import useAuth from "./hooks/useAuth";
import AuthProvider from "./providers/AuthProvider";
import NotificationProvider from "./providers/NotificationProvider";
import { strings } from "./utils/i18n.utils.js";

import LogRocket from "logrocket";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./App.scss";
import { ActivitySession } from "./components/ActivitySession";
import BottomBarMenu from "./components/BottomBarMenu";
import { Notifications } from "./components/Notifications";
import { TopBar } from "./components/TopBar";
import { UpdateApp } from "./components/UpdateApp";
import { ZataLoader } from "./components/ZataLoader";
import useAPI from "./hooks/useAPI";
import { useAudio } from "./hooks/useAudio";
import Landing from "./product/Landing.js";
import AudioProvider from "./providers/AudioProvider";
import LanguageProvider from "./providers/LanguageProvider.js";
import TitleProvider from "./providers/TitleProvider";
import WalkthroughProvider from "./providers/WalkthroughProvider";
import Exercises from "./screens/Exercises";
import AllActivityHistory from "./screens/History";
import { Home } from "./screens/Home";
import SkillScreen from "./screens/SkillScreen.js";
import { Terms } from "./screens/Terms";
import { TestPhrase } from "./test/TestPhrase";
import About from "./product/About.js";
import Sidebar from "./components/Sidebar.js";
import SidebarProvider from "./providers/SidebarProvider.js";
import Footer from "./product/Footer.js";
import { ProductOverview } from "./product/ProductOverview.js";
import Teachers from "./product/Teachers.js";
import { AddTopic } from "./components/AddTopic.js";
import Components from "./test/Components.js";
import YouScreen from "./screens/You.js";
import Statistics from "./components/PracticeActivityStatistics.js";
import ScrollToTop from "./components/ScrollToTop.js";
import SkillsScreen from "./screens/Skills.js";
import { isMobile } from "./utils/platform.utils.js";
import Notes from "./screens/Notes.js";
import NoteScreen from "./screens/Note.js";
import { ActivityScreen } from "./screens/ActivityScreen.js";
import ActivityVocabScreen from "./screens/ActivityVocabScreen.js";
import TestBoard from "./screens/test/TestBoard.js";
import { Talk } from "./components/Talk.js";
import PlanScreen from "./screens/PlanScreen.js";
import TopicScreen from "./screens/TopicScreen.js";

export default function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

function TitleScreen({}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            }}>
            <img src={`/jetway/emoji_u1f9e0.svg`} style={{ width: "5rem" }} />
            <h1 style={{ fontWeight: "400" }}>jetway</h1>
            <h3 style={{ fontWeight: "400" }}>https://jetway.ai</h3>
        </div>
    );
}

function App() {
    useEffect(() => {
        if (window.Capacitor) {
            document.body.classList.add("ionic-root");
        }

        strings.setLanguage(navigator.language || "en");
    }, []);

    return (
        <NotificationProvider>
            <Notifications />
            <ScrollToTop />
            {!window.Capacitor && <UpdateApp />}

            <Providers>
                <Switch>
                    <Route exact path="/">
                        <PageWithProductNavBar>
                            <Landing />
                        </PageWithProductNavBar>
                    </Route>
                    <Route exact path="/overview">
                        <PageWithProductNavBar>
                            <ProductOverview />
                        </PageWithProductNavBar>
                    </Route>
                    <Route exact path="/teachers">
                        <PageWithProductNavBar>
                            <Teachers />
                        </PageWithProductNavBar>
                    </Route>
                    <Route exact path="/about">
                        <PageWithProductNavBar>
                            <About />
                        </PageWithProductNavBar>
                    </Route>
                    <Route exact path="/login">
                        <PageWithProductNavBar>
                            <LoginStandalone />
                        </PageWithProductNavBar>
                    </Route>
                    <Route exact path="/logout">
                        <PageWithProductNavBar>
                            <Logout />
                        </PageWithProductNavBar>
                    </Route>
                </Switch>

                <Switch>
                    <Route exact path="/terms">
                        <PageWithFullHeightAndNavBar>
                            <Terms />
                        </PageWithFullHeightAndNavBar>
                    </Route>

                    <Route exact path="/title">
                        <PageWithNavBar>
                            <TitleScreen />
                        </PageWithNavBar>
                    </Route>

                    <Route exact path="/login/:socialApp/callback">
                        <LoginCallback />
                    </Route>

                    <PrivateRoute path="/start" exact>
                        <PageWithFullHeightAndNavBar>
                            <Start />
                        </PageWithFullHeightAndNavBar>
                    </PrivateRoute>

                    <PrivateRoute exact path="/languages/:language/:nativeLanguage/level">
                        <PageWithFullHeightAndNavBar>
                            <SelectLevelStandalone />
                        </PageWithFullHeightAndNavBar>
                    </PrivateRoute>

                    <PrivateRoute exact path="/spaces/:currentSpaceId/you">
                        <PageWithNavBar>
                            <YouScreen />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PrivateRoute exact path="/activities/:activityId/statistics">
                        <PageWithNavBar>
                            <Statistics />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PrivateRoute exact path="/spaces/:currentSpaceId/settings">
                        <PageWithNavBar>
                            <SettingsScreen />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PrivateRoute path="/spaces/:currentSpaceId/home" exact={true}>
                        <PageWithNavBar fullWidth={false}>
                            <Home />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PrivateRoute path="/spaces/:currentSpaceId/talk">
                        <PageWithNavBar>
                            <Talk />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PrivateRoute path="/spaces/:currentSpaceId/chat">
                        <BottomAlign>
                            <PageWithoutNavBar>
                                <Prompt />
                            </PageWithoutNavBar>
                        </BottomAlign>
                    </PrivateRoute>

                    <PublicRoute path="/spaces/:currentSpaceId/plan" exact={true}>
                        <PageWithNavBar>
                            <PlanScreen />
                        </PageWithNavBar>
                    </PublicRoute>

                    <PublicRoute path="/spaces/:currentSpaceId/exercises" exact={true}>
                        <PageWithNavBar>
                            <Exercises />
                        </PageWithNavBar>
                    </PublicRoute>

                    <PublicRoute path="/spaces/:currentSpaceId/skills/:skillId" exact={true}>
                        <PageWithNavBar>
                            <SkillScreen />
                        </PageWithNavBar>
                    </PublicRoute>

                    <PublicRoute path="/spaces/:currentSpaceId/history" exact={true}>
                        <PageWithNavBar>
                            <AllActivityHistory />
                        </PageWithNavBar>
                    </PublicRoute>

                    <PublicRoute path="/spaces/:currentSpaceId/skills" exact={true}>
                        <PageWithNavBar>
                            <SkillsScreen />
                        </PageWithNavBar>
                    </PublicRoute>

                    <PrivateRoute path="/spaces/:currentSpaceId/topics/new" exact={true}>
                        <PageWithNavBar showBottomBar={false}>
                            <AddTopic />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PrivateRoute path="/topics/:language/:topicName" exact={true}>
                        <PageWithNavBar>
                            <TopicScreen />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PublicRoute path="/activities/:activityId" exact={true}>
                        <PageWithNavBar>
                            <ActivityScreen />
                        </PageWithNavBar>
                    </PublicRoute>

                    <PublicRoute path="/activities/:activityId/vocab" exact={true}>
                        <PageWithNavBar>
                            <ActivityVocabScreen />
                        </PageWithNavBar>
                    </PublicRoute>

                    <PrivateRoute path="/spaces/:spaceId/notes/new" exact={true}>
                        <PageWithNavBar>
                            <NoteScreen />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PrivateRoute path="/spaces/:spaceId/notes/:noteId" exact={true}>
                        <PageWithNavBar>
                            <NoteScreen />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PublicRoute path="/spaces/:spaceId/history/:activitySessionId" exact={true}>
                        <PageWithNavBar>
                            <ActivitySession />
                        </PageWithNavBar>
                    </PublicRoute>

                    <PrivateRoute path="/tests/board" exact={true}>
                        <PageWithNavBar>
                            <TestBoard />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PrivateRoute path="/tests/phrase" exact={true}>
                        <PageWithNavBar>
                            <TestPhrase />
                        </PageWithNavBar>
                    </PrivateRoute>

                    <PrivateRoute path="/tests/components" exact={true}>
                        <PageWithNavBar>
                            <Components />
                        </PageWithNavBar>
                    </PrivateRoute>
                </Switch>
            </Providers>
        </NotificationProvider>
    );
}

function Providers({ children }) {
    return (
        <AuthProvider>
            <AudioProvider>
                <TitleProvider>
                    <WalkthroughProvider>
                        <DndProvider backend={HTML5Backend}>{children}</DndProvider>
                    </WalkthroughProvider>
                </TitleProvider>
            </AudioProvider>
        </AuthProvider>
    );
}

function PublicRoute({ children, ...rest }) {
    const { user } = useAuth();
    const history = useHistory();

    return (
        <Route {...rest}>
            {children}
            {!user && (
                <BottomBar>
                    <div
                        style={{
                            margin: "0 1rem",
                            display: "flex",
                            flexDirection: "row",
                        }}>
                        <div style={{ flexGrow: 10, margin: "1rem" }}>{strings.unauthenticated_call_to_action}</div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "end",
                            }}>
                            <div>
                                <Button
                                    primary
                                    onClick={() => history.push(`/?redirectTo=${window.location.pathname}`)}
                                    size="large">
                                    Create Account
                                </Button>
                            </div>
                        </div>
                    </div>
                </BottomBar>
            )}
        </Route>
    );
}

function BottomBar(props) {
    return createPortal(<div id="bottombar">{props.children}</div>, document.getElementById("root"));
}

function PrivateRoute({ children, ...rest }) {
    const { user } = useAuth();

    useEffect(() => {
        if (user && window.location.host !== "localhost:3000") {
            console.log("Logging in with LogRocket", user);
            if (window.location.host !== "localhost:3000") {
                console.log("Initiailizing LogRocket", window.location.host);
                LogRocket.init("n4eqwe/jetway");
                LogRocket.identify(user.id, {
                    email: user.email,
                    // Add your own custom user variables here, ie:
                    currentLanguage: user.current_language,
                    currentNativeLanguage: user.current_native_language,
                });
            }
        }
        console.log("User", user);
    }, [user]);

    if (!user) {
        return (
            <PageWithNavBar>
                <LoginStandalone />
            </PageWithNavBar>
        );
    }
    if (user) return <Route {...rest} render={() => children} />;

    return <div />;
}

function PageWithNavBar({ children, className, showBottomBar = true, fullWidth = false, ...rest }) {
    const { user } = useAuth();
    return (
        <LanguageProvider>
            <Container className={`content-container ${fullWidth && "full-width"}`}>
                {" "}
                <TopBar fullWidth={fullWidth && !isMobile.any()} />
                {children}
                {!fullWidth && <Divider hidden />}
            </Container>
            {user && showBottomBar && <BottomBarMenu />}
        </LanguageProvider>
    );
}

function PageWithProductNavBar({ children, className, ...rest }) {
    return (
        <>
            <SidebarProvider>
                <TopBar fullWidth={true} />
                <Sidebar />
                {children}

                <Divider hidden />
                <Footer />
            </SidebarProvider>
        </>
    );
}

function PageWithFullHeightAndNavBar({ children, className, ...rest }) {
    return (
        <LanguageProvider>
            <Container className="content-container" style={{ height: "100%" }}>
                <TopBar />

                {children}

                <Divider hidden />
            </Container>
        </LanguageProvider>
    );
}

function PageWithoutNavBar({ children, ...rest }) {
    return (
        <LanguageProvider>
            <Container>{children}</Container>
        </LanguageProvider>
    );
}

function BottomAlign({ children, ...rest }) {
    return (
        <div className="bottomalign">
            <div className="spacer" />
            {children}
        </div>
    );
}

function Logout() {
    const history = useHistory();
    const [{ loading }, callAPI] = useAPI();
    const { setUser } = useAuth();

    useEffect(() => {
        callAPI("POST", `/api/user/logout`);
    }, []);

    if (loading) return <ZataLoader />;

    setUser(null);

    history.push("/");

    return "";
}
