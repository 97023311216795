import { Progress } from "semantic-ui-react";
import useAuth from "../hooks/useAuth";

const DailyProgressBar = ({ progress }) => {
    const { statistics, languageSettings } = useAuth();

    return (
        <Progress
            size="small"
            style={{
                margin: "0 0 0.15rem 0",
                display: "inline-block",
                backgroundColor: "var(--primary-color-inverted)",
                color: "var(--primary-color-inverted)",
                border: "2px solid var(--primary-background-color-inverted)",
                width: "10rem",
                verticalAlign: "middle",
            }}
            progress="ratio"
            value={statistics?.points}
            total={languageSettings?.daily_points_goal}></Progress>
    );
};

export default DailyProgressBar;
