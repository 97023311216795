import { Modal, Popup } from "semantic-ui-react";

const FlashcardDetails = ({ flashcard, reviews }) => {
    return (
        <div>
            <div>{flashcard.id}</div>
            <div>Skill: {flashcard.skill.name}</div>
            <div>Word: {flashcard.from_phrase.text}</div>
        </div>
    );
};

export const FlashcardDetailsPopup = ({ trigger, flashcard, reviews }) => {
    return (
        <Modal
            trigger={trigger}
            header="Exercise History"
            content={<FlashcardDetails flashcard={flashcard} reviews={reviews} />}
        />
    );
};

export default FlashcardDetails;
