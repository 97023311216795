import { useEffect } from "react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import useTitle from "../hooks/useTitle";
import Skill from "../components/Skill";
import { ArrowArcRight, Barbell, Lightbulb } from "@phosphor-icons/react";
import NextUp from "../components/NextUp";

const PlanScreen = ({}) => {
    const [planContextAPI, callPlanContextAPI] = useAPI();
    const { currentSpace } = useAuth();

    useEffect(() => {
        callPlanContextAPI("GET", `/api/spaces/${currentSpace.id}/plan?include_context`);
    }, [currentSpace]);

    if (planContextAPI.loading || !planContextAPI.response) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Next Up</h1>
            <NextUp plan={planContextAPI.response} />

            <h1>Your study context</h1>
            <p>
                The study context is used to determine what you focus on next. It's a subset of all the skills and
                vocabulary in your queue.
            </p>
            <h2>Key</h2>
            <p>
                <Barbell /> You are learning this word
                <br />
                <ArrowArcRight /> You told Jetway you know this skill + vocabulary word combinartion already and not to
                study it
                <br />
                <Lightbulb /> Jetway inferred that you know this skill + vocabulary combination based on other exercises
                you did you vocabulary you said you already knew
                <br />
            </p>
            <h2>Next skills to learn</h2>
            <p>Skills and vocabulary that you have not yet studied.</p>
            {planContextAPI.response?.context.skills_next?.map((skill) => (
                <Skill skill={skill} />
            ))}

            <h2>Skills to reinforce</h2>
            <p>
                Skills and vocabulary that you have done some exercises for already or which recently received grammar
                corrections for.
            </p>
            {planContextAPI.response?.context.skills_currently_learning?.map((skill) => (
                <Skill skill={skill} />
            ))}

            <h2>Completed Skills</h2>
            <p>
                Skills and vocabulary that you know very well, that Jetway inferred you know, or that you told Jetway
                you know.
            </p>
            {planContextAPI.response?.context.skills_mastered?.map((skill) => (
                <Skill skill={skill} />
            ))}
        </div>
    );
};

export default PlanScreen;
