import { Alarm, CalendarBlank } from "@phosphor-icons/react";
import { Loader, Segment } from "semantic-ui-react";
import Phrase from "./Phrase";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import { SkillPhrases } from "./Skill";
import { Link } from "react-router-dom/cjs/react-router-dom";

const NextUp = ({ plan }) => {
    const { statistics, currentSpace } = useAuth();

    return (
        <>
            <ul>
                {statistics?.skill_vocab_pairs_needing_review > 0 && (
                    <li>
                        <Alarm />{" "}
                        <Link to={`/spaces/${currentSpace.id}/exercises`}>
                            {statistics?.skill_vocab_pairs_needing_review} {strings.flashcards_needing_review}
                        </Link>
                    </li>
                )}

                {plan.skills?.map((skill) => (
                    <li>
                        <Link to={`/spaces/${currentSpace.id}/skills/${skill.id}`}>{skill.name_native}</Link> &mdash;{" "}
                        <SkillPhrases skill={skill} />
                    </li>
                ))}

                {plan.questions?.map((question) => (
                    <li>
                        <Phrase
                            phrase={question.phrase}
                            inline={true}
                            translationLanguage={currentSpace.native_language}
                        />{" "}
                    </li>
                ))}
                {plan.plan_generation_task_id && (
                    <li>
                        <Loader active={true} inline={true} size="tiny" /> {strings.building_study_plan}
                    </li>
                )}
                {plan.plan_generation_task_id === null &&
                    (plan.skills === null || plan.skills.length === 0) &&
                    statistics?.skill_vocab_pairs_needing_review === 0 && <li>{strings.nothing_to_study}</li>}
            </ul>
        </>
    );
};

export default NextUp;
