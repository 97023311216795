import React, { useState, useEffect, useRef } from "react";
import _, { add } from "lodash";
import { Button, Loader, Popup } from "semantic-ui-react";
import { ZataLoader } from "./ZataLoader";
import { useParams } from "react-router-dom";
import { AudioPlayButton } from "./AudioPlayer";
import { useEventListener } from "../hooks/useEventListener";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import { youglishUrl } from "./Youglish";
import { useAudio } from "../hooks/useAudio";
import { Eye, Plus, Translate, Trash } from "@phosphor-icons/react";

const emptyList = [];

export default function Phrase({
    phrase,
    alignments,
    translations,
    translationLanguage,
    fromOrTarget = "from",
    unknownWordIds = emptyList,
    setUnknownWordIds = () => null,
    additionalActions,
    grammarCorrectedWordIds = emptyList,
    missedWordIds = emptyList,
    markUknownRemotely,
    autoPlayAudio,
    inline,
    blanks = null,
    allowFullTranslation = false,
    squidgyId,
    prefix,
    setPlayingAudio,
    promptMessageId,
    enableButtons = true,
    giveUp = false,
    openWord = null,
    setRevealed: setRevealedParent,
}) {
    const isTranslatable = translationLanguage && translationLanguage !== phrase.language;

    const [isShowTranslation, setShowTranslation] = useState(false);
    const [, setPlayed] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [internalTranslations, setTranslations] = useState(translations || phrase.translations);
    const [internalAlignments, setAlignments] = useState(alignments || phrase.alignments);
    const [loadTranslationsAPI, callLoadTranslationsAPI] = useAPI({
        loading: false,
    });
    const [, callMarkUnknownAPI] = useAPI();
    const [isRevealed, setRevealed] = useState(false);
    const { queueAudioToPlay } = useAudio();

    const loadTranslations = () => {
        if (
            !internalTranslations ||
            internalTranslations.length === 0 ||
            internalAlignments === null ||
            internalAlignments.length === 0
        ) {
            callLoadTranslationsAPI("GET", `/api/phrases/${phrase.id}/translations/${translationLanguage}`);
        }
    };

    useEffect(() => {
        if (loadTranslationsAPI.response) {
            setTranslations(loadTranslationsAPI.response.translations);
            setAlignments(loadTranslationsAPI.response.alignments);
        }
    }, [loadTranslationsAPI.response]);

    useEffect(() => {
        if (giveUp) {
            reveal();
        }
    }, [giveUp]);

    function showTranslation() {
        setShowTranslation(true);
        loadTranslations();
        setPlayed(true);

        if (markUknownRemotely && promptMessageId) {
            callMarkUnknownAPI(
                "POST",
                `/api/prompt_messages/${promptMessageId}/mark_phrase_unknown`,
                null,
                null,
                (error) => console.log("Couldn't invoke mark unknown API", error)
            );
        }
    }

    function reveal() {
        setRevealed(true);
        setRevealedParent && setRevealedParent(true);
        loadTranslations();
        if (blanks && blanks.length > 0) {
            setUnknownWordIds(unknownWordIds.concat(blanks));
        }

        if (blanks && blanks.length > 0) {
            let url = `/api/phrases/${phrase.id}/audio?word_ids=${blanks}`;
            if (squidgyId) {
                url += `&squidgy_id=${squidgyId}&`;
            }
            queueAudioToPlay(url, true);
        }
    }

    useEffect(() => {
        let url = process.env.REACT_APP_SERVER_URL + `/api/phrases/${phrase.id}/audio?`;
        if (squidgyId) {
            url += `squidgy_id=${squidgyId}&`;
        }
        if (blanks && !isRevealed) {
            url += `blanks=${blanks}`;
        }

        setAudioUrl(url);
    }, [blanks, phrase, squidgyId]);

    return (
        <span>
            {!isTranslatable && phrase.text}
            {isTranslatable && (
                <span>
                    <PhraseInternal
                        key={phrase.id}
                        phrase={phrase}
                        translations={internalTranslations}
                        alignments={internalAlignments}
                        loadTranslations={loadTranslations}
                        fromOrTarget={fromOrTarget}
                        unknownWordIds={unknownWordIds}
                        setUnknownWordIds={setUnknownWordIds}
                        grammarCorrectedWordIds={
                            grammarCorrectedWordIds ? Object.keys(grammarCorrectedWordIds) : emptyList
                        }
                        missedWordIds={missedWordIds}
                        markUknownRemotely={markUknownRemotely}
                        autoPlayAudio={autoPlayAudio}
                        inline={inline}
                        blanks={blanks}
                        prefix={prefix}
                        squidgyId={squidgyId}
                        promptMessageId={promptMessageId}
                        isRevealed={isRevealed}
                        openWord={openWord}
                    />

                    {(isRevealed || isShowTranslation) && loadTranslationsAPI.loading && (
                        <div className="PhraseRevealedTranslation">Loading translation...</div>
                    )}
                    {(isRevealed || isShowTranslation) && internalTranslations && internalTranslations.length > 0 && (
                        <div className="PhraseRevealedTranslation">{internalTranslations[0].text}</div>
                    )}
                </span>
            )}

            {!inline && enableButtons && (
                <div className="phrase-button-row">
                    {audioUrl && (
                        <AudioPlayButton
                            url={audioUrl}
                            size={"small"}
                            autoPlay={autoPlayAudio}
                            setPlayingAudio={setPlayingAudio}
                            buttonStyle="button"
                            includeText={true}
                        />
                    )}

                    {!blanks && isTranslatable && (
                        <Button
                            compact
                            size={"small"}
                            icon={<Translate />}
                            onClick={showTranslation}
                            content={strings.phrase_translate}
                            disabled={!allowFullTranslation || isShowTranslation}
                        />
                    )}

                    {blanks && isTranslatable && (
                        <Button
                            compact
                            size={"small"}
                            icon={<Eye />}
                            onClick={reveal}
                            content={strings.phrase_reveal}
                            disabled={isRevealed}
                        />
                    )}

                    {additionalActions}
                </div>
            )}
        </span>
    );
}

function PhraseInternal({
    phrase,
    alignments,
    translations,
    loadTranslations,
    fromOrTarget,
    unknownWordIds,
    setUnknownWordIds,
    grammarCorrectedWordIds,
    missedWordIds,
    markUknownRemotely,
    inline,
    blanks,
    squidgyId,
    promptMessageId,
    prefix,
    isRevealed,
    className,
    openWord,
}) {
    const [showTranslation, setShowTranslation] = useState(null);

    useEffect(() => {
        if (showTranslation && (!translations || translations.length === 0 || !alignments || alignments.length === 0)) {
            loadTranslations();
        }
    }, [showTranslation]);

    function findTranslationWord(word_id) {
        for (const t of translations) {
            for (const w of t.words) {
                if (w.id === word_id) {
                    return [t, w];
                }
            }
        }

        throw new Error("Could not find translation for word " + word_id);
    }

    function findTranslationsForWord(word) {
        let wordTranslations = [];
        for (const alignment of alignments) {
            let from_word_ids =
                fromOrTarget === "from" || (blanks && blanks.length > 0)
                    ? alignment.from_word_ids
                    : alignment.to_word_ids;
            if (from_word_ids.includes(word.id)) {
                let last_word = null;
                let translated_words = [];
                let translated_word_ids = [];
                let translated_lemmas = [];

                let to_word_ids =
                    fromOrTarget === "from" || (blanks && blanks.length > 0)
                        ? alignment.to_word_ids
                        : alignment.from_word_ids;
                let t_phrase = null;

                for (let word_id of to_word_ids) {
                    let [t_phrase2, word] = findTranslationWord(word_id);
                    // ohhhh this is ugly sorry
                    t_phrase = t_phrase2;

                    if (last_word && last_word.order + 1 === word.order) {
                        translated_words.push(" ");
                        translated_lemmas.push(null);
                    } else if (last_word) {
                        translated_words.push("...");
                        translated_lemmas.push(null);
                    }

                    translated_word_ids.push(word.id);
                    translated_words.push(word.text);
                    translated_lemmas.push(word.lemma);
                    last_word = word;
                }

                if (t_phrase !== null) {
                    wordTranslations.push({
                        phraseId: t_phrase.id,
                        language: t_phrase.language,
                        words: translated_words,
                        wordIds: translated_word_ids,
                        lemmas: translated_lemmas,
                    });
                }
            }
        }

        if (wordTranslations.length === 0) {
            console.log("Could not find translation for " + word.id, "Alignments", alignments);
        }

        return wordTranslations;
    }

    return (
        <div
            className={className ? "Phrase " + className : "Phrase"}
            key={phrase.id}
            style={inline !== true ? { display: "flex", justifyContent: "flex-end" } : { display: "inline" }}>
            <div style={inline !== true ? { flexGrow: 1 } : { display: "inline" }}>
                {prefix}
                {_.map(phrase.words, (w) => (
                    <Word
                        key={w.id}
                        word={w}
                        phrase={phrase}
                        translationsLoaded={
                            translations && alignments && translations.length > 0 && alignments.length > 0
                        }
                        findTranslationsForWord={findTranslationsForWord}
                        showTranslation={showTranslation}
                        setShowTranslation={setShowTranslation}
                        grammarCorrectedWordIds={grammarCorrectedWordIds}
                        unknownWordIds={unknownWordIds}
                        setUnknownWordIds={setUnknownWordIds}
                        missedWordIds={missedWordIds}
                        markUknownRemotely={markUknownRemotely}
                        blank={blanks !== null && blanks.includes(w.id) && w.pos !== "PUNCT"}
                        squidgyId={squidgyId}
                        promptMessageId={promptMessageId}
                        isRevealed={isRevealed}
                        isOpenInitially={openWord === w}
                    />
                ))}
            </div>
        </div>
    );
}

const Word = ({
    word,
    phrase,
    showTranslation,
    setShowTranslation,
    translationsLoaded,
    findTranslationsForWord,
    unknownWordIds,
    setUnknownWordIds,
    missedWordIds,
    translations,
    markUknownRemotely,
    blank,
    squidgyId,
    promptMessageId,
    isRevealed,
    isOpenInitially,
}) => {
    let { language, nativeLanguage } = useParams();
    const [isUnknown, setUnknown] = useState(unknownWordIds.includes(word.id));
    const [isMissed, setMissed] = useState(false);
    const [isOpen, setIsOpen] = useState(isOpenInitially);
    const [translationTexts, setTranslationTexts] = useState(null);
    const [translationText, setTranslationText] = useState("");
    const [markUnknownAPI, callMarkUnknownAPI] = useAPI({ loading: false });
    const { user } = useAuth();
    const [position, setPosition] = useState("bottom center");

    const ref = useRef(null);

    useEffect(updatePosition, [ref]);
    useEventListener("resize", updatePosition, window.visualViewport);

    function spaceAfter() {
        var spaceAfter = "";
        let wordIdx = phrase.words.indexOf(word);
        if (phrase.words.length > wordIdx + 1) {
            // convert into an array so we can handle unicode correctly
            // https://stackoverflow.com/questions/70302587/how-to-use-substring-with-special-unicode-characters
            let chars = [...phrase.text];
            let wordChars = [...word.text];
            let wordIdx = phrase.words.indexOf(word);
            let start = word.offset + wordChars.length;
            let end = phrase.words[wordIdx + 1].offset;

            spaceAfter = chars.slice(start, end).join("");
        }

        // replace newline with <br> so it renders correctly
        spaceAfter = spaceAfter.replace(/\n/g, "<br>");

        return React.createElement("span", {
            dangerouslySetInnerHTML: { __html: spaceAfter },
        });
    }

    function updatePosition() {
        if (!ref.current) {
            return;
        }

        const rect = ref.current.getBoundingClientRect();
        // if within 100 px of bottom of screen use top, otherwise bottom
        const topOrBottom = window.visualViewport.height - rect.bottom < 100 ? "top" : "bottom";

        if (rect.left < 100) {
            setPosition(topOrBottom + " right");
        }
        if (rect.right < 100) {
            setPosition(topOrBottom + " left");
        } else {
            setPosition(topOrBottom + " center");
        }
    }

    useEffect(() => {
        if (!translationsLoaded) {
            return;
        }

        if (blank) {
            let words = [
                {
                    id: [word.id],
                    words: [word.text],
                    lemmas: [word.lemma],
                    language: phrase.language,
                    phraseId: phrase.id,
                    wordIds: [word.id],
                },
            ];

            setTranslationTexts(words);
        } else {
            translations = findTranslationsForWord(word);

            setTranslationTexts(translations);

            if (translations.length > 0) {
                let joinedTranslation = [];
                for (const text of translations[0]["words"]) {
                    if (joinedTranslation.length > 0) {
                        joinedTranslation.push(" ");
                    }

                    joinedTranslation.push(text);
                }
                setTranslationText(joinedTranslation.join(""));
            } else {
                setTranslationText("");
            }
        }
    }, [translationsLoaded]);

    useEffect(() => {
        if (showTranslation && word.id === showTranslation.id) {
            setUnknownWordIds(unknownWordIds.concat(word.id));
        } else if (unknownWordIds && unknownWordIds.includes(word.id)) {
            setIsOpen(false);
        }
    }, [showTranslation]);

    useEffect(() => {
        if (unknownWordIds && unknownWordIds.includes(word.id)) {
            setUnknown(true);
        }
        if (missedWordIds && missedWordIds.includes(word.id)) {
            setMissed(true);
        }
    }, [unknownWordIds]);

    function markUnknown() {
        if (markUknownRemotely && markUknownRemotely === true && promptMessageId && user) {
            callMarkUnknownAPI(
                "POST",
                `/api/prompt_messages/${promptMessageId}/words/${word.id}/mark_unknown`,
                null,
                null,
                (error) => console.log("Couldn't invoke mark unknown API", error)
            );
        }
    }

    function toggleTranslation(e) {
        setShowTranslation(word);
    }

    function countSpaces() {
        // use a fixed length, otherwise you can guess determiners based on the length
        let description = describe();

        if (description) {
            return description.length * 0.5;
        }

        return word.text.length * 0.7 > 2 ? word.text.length * 0.7 : 2;
    }

    function describe() {
        return null;
    }

    function addSavedWord() {
        callMarkUnknownAPI(
            "POST",
            `/api/prompt_messages/${promptMessageId}/words/${word.id}/mark_unknown?save_word=true`,
            null,
            null,
            (error) => console.log("Couldn't invoke mark unknown API", error)
        );
    }
    function removeSavedWord() {
        callMarkUnknownAPI(
            "POST",
            `/api/prompt_messages/${promptMessageId}/words/${word.id}/remove_saved_word`,
            null,
            null,
            (error) => console.log("Couldn't invoke mark unknown API", error)
        );
    }

    function open() {
        setIsOpen(true);
        markUnknown();
    }
    function close() {
        setIsOpen(false);
    }

    return (
        <span key={word.id} ref={ref}>
            <Popup
                pinned
                on={"click"}
                open={isOpen}
                onOpen={open}
                onClose={close}
                position={position}
                trigger={
                    <span>
                        {blank === true && (
                            <>
                                <span
                                    style={{
                                        display: "inline-flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}>
                                    <span
                                        className={
                                            (isUnknown ? "Word WordUnknown WordBlank" : "Word WordBlank") +
                                            (isMissed ? " WordMissed" : "")
                                        }
                                        style={{ display: "inline", minWidth: countSpaces() + "rem" }}
                                        onMouseUp={toggleTranslation}
                                        onTouchStart={toggleTranslation}>
                                        {isRevealed ? word.text : <>&nbsp;</>}
                                    </span>
                                    <span className={"WordDescription"}>{describe()}</span>
                                </span>
                                {spaceAfter()}
                            </>
                        )}

                        {blank === false && word.pos !== "PUNCT" && (
                            <>
                                <span
                                    className={
                                        (isUnknown ? "Word WordUnknown" : "Word") + (isMissed ? " WordMissed" : "")
                                    }
                                    onMouseUp={toggleTranslation}
                                    onTouchStart={toggleTranslation}>
                                    {word.text}
                                </span>
                                {spaceAfter()}
                            </>
                        )}

                        {blank === false && word.pos === "PUNCT" && (
                            <>
                                {word.text}
                                {spaceAfter()}
                            </>
                        )}
                    </span>
                }
                content={
                    <>
                        {!translationTexts && <ZataLoader />}
                        {translationTexts &&
                            _.map(translationTexts, (t, i) => {
                                return (
                                    <div key={i}>
                                        <div style={{ textAlign: "center" }}>
                                            {t.words &&
                                                t.words.map((tWord, index) => (
                                                    <>{tWord !== " " ? tWord : <>&nbsp;</>}</>
                                                ))}
                                        </div>

                                        {markUnknownAPI.loading && (
                                            <div style={{ textAlign: "center", margin: "1rem 0" }}>
                                                <i>{strings.saving}</i>
                                            </div>
                                        )}
                                        {!markUnknownAPI.loading && markUnknownAPI.response && (
                                            <div style={{ textAlign: "center", margin: "1rem 0" }}>
                                                {!markUnknownAPI.response.skill && (
                                                    <Button
                                                        compact
                                                        icon={<Plus />}
                                                        content={strings.save}
                                                        onClick={addSavedWord}
                                                    />
                                                )}
                                                {markUnknownAPI.response.skill && (
                                                    <>
                                                        <i>{markUnknownAPI.response.skill.name_native}</i>
                                                        &nbsp;
                                                        <Button compact icon={<Trash />} onClick={removeSavedWord} />
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        {phrase.language === nativeLanguage && (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: "0.5rem",
                                                }}>
                                                <AudioPlayButton
                                                    buttonStyle={"button"}
                                                    url={`/api/phrases/${t.phraseId}/audio?word_ids=${t.wordIds}${
                                                        squidgyId ? "&squidgy_id=" + squidgyId : ""
                                                    }`}
                                                    autoPlay={nativeLanguage === phrase.language}
                                                    showText={false}
                                                />
                                                <Button
                                                    compact
                                                    icon="book"
                                                    onClick={() =>
                                                        window.open(
                                                            `https://${language}.wiktionary.org/wiki/${translationText}`
                                                        )
                                                    }
                                                />
                                                <Button
                                                    compact
                                                    icon="youtube"
                                                    onClick={() => window.open(youglishUrl(language, translationText))}
                                                />
                                                <Button
                                                    compact
                                                    icon="google"
                                                    onClick={() =>
                                                        window.open(
                                                            `https://google.com/search?q=${translationText}&lr=${language}`
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}

                                        {phrase.language !== nativeLanguage && (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: "0.5rem",
                                                }}>
                                                <AudioPlayButton
                                                    buttonStyle={"button"}
                                                    url={`/api/phrases/${phrase.id}/audio?word_ids=${word.id}${
                                                        squidgyId ? "&squidgy_id=" + squidgyId : ""
                                                    }`}
                                                    autoPlay={nativeLanguage === phrase.language}
                                                    showText={false}
                                                />
                                                <Button
                                                    compact
                                                    icon="book"
                                                    onClick={() =>
                                                        window.open(
                                                            `https://${language}.wiktionary.org/wiki/${word.lemma}`
                                                        )
                                                    }
                                                />
                                                <Button
                                                    compact
                                                    icon="youtube"
                                                    onClick={() => window.open(youglishUrl(language, word.text))}
                                                />
                                                <Button
                                                    compact
                                                    icon="google"
                                                    onClick={() =>
                                                        window.open(
                                                            `https://google.com/search?q=${word.text}&lr=${language}`
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </>
                }
            />
        </span>
    );
};
