import React, { useEffect, useState } from "react";
import { StatisticsBar, StatisticsRow, StreakIcon } from "../components/StatisticsBar";

import { Alarm, Barbell, CalendarBlank, Flame } from "@phosphor-icons/react";
import { useLanguage } from "../hooks/useLanguage";
import { strings } from "../utils/i18n.utils";
import DailyProgressBar from "./DailyProgressBar";
import "./TopStatistics.css";

export function StreakBar({ progress: initialProgress }) {
    const [progress, setProgress] = useState([]);
    const { nativeLanguage } = useLanguage();

    useEffect(() => {
        if (!initialProgress) {
            return;
        }

        // get last 7 days
        let newProgress = initialProgress.slice(initialProgress.length - 7);

        newProgress = newProgress.map((stats, index) => {
            // Day in the format "2021-01-01"
            // We have to reformat it because JavaScript dates are fuuuucked and it gets interpreted as one day earlier
            const date = new Date(stats.day.replace(/-/g, "/"));
            const dayName = date.toLocaleString(nativeLanguage, { weekday: "long" });

            return {
                statistics: stats,
                dayName: dayName,
            };
        });
        console.log("Progress", newProgress);

        setProgress(newProgress);
    }, []);

    return (
        <StatisticsBar>
            <StatisticsRow>
                {progress.map((p, index) => (
                    <div
                        style={{
                            flexBasis: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "0.88rem",
                        }}>
                        <div>{p.dayName.substring(0, 1)}</div>
                        <div>
                            <StreakIcon
                                type={
                                    p.statistics.streak_freeze
                                        ? "freeze"
                                        : p.statistics.points_goal_met
                                        ? "streak"
                                        : index === 6
                                        ? "today"
                                        : null
                                }
                            />
                        </div>
                    </div>
                ))}
            </StatisticsRow>
        </StatisticsBar>
    );
}

export const TopStatistics = ({ statistics }) => {
    return (
        <>
            <h1>{strings.progress}</h1>
            <div className="top-statistics">
                <div className={"top-statistics-bar"}>
                    <div>
                        <CalendarBlank weight="bold" className="solid-icon" /> <DailyProgressBar />{" "}
                        {strings.streak_today}
                    </div>
                    <div>
                        <Flame weight="bold" className="solid-icon" /> {statistics?.streak_days} {strings.stats_streak}
                    </div>

                    <div>
                        <Alarm weight="bold" className="solid-icon" />{" "}
                        {statistics?.skill_vocab_pairs_needing_review?.toString()} {strings.needing_review}
                    </div>
                    <div>
                        <Barbell weight="bold" className="solid-icon" />{" "}
                        {statistics?.skill_vocab_pairs_studied?.toString()}/
                        {statistics?.skill_vocab_pairs_total?.toString()} {strings.skill_vocab_pairs_studied}
                    </div>
                </div>
            </div>
        </>
    );
};
