import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import Skill from "../components/Skill";
import { strings } from "../utils/i18n.utils";
import useTask from "../hooks/useTask";
import useNotifications from "../hooks/useNotifications";

export const Topic = ({ topic, reloadTopic }) => {
    useTask(topic?.generation_task_id, reloadTopic);

    return (
        <div>
            {topic?.generation_task_id && <Segment>{strings.topic_building}</Segment>}

            {topic.skills?.length > 0 && (
                <>
                    <h2>Skills</h2>
                    {topic.skills.map((skill) => (
                        <Skill key={skill.id} skill={skill} showOutcomes={true} />
                    ))}
                </>
            )}
        </div>
    );
};
