import { useEffect } from "react";
import useAPI from "../hooks/useAPI";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import { Button, Segment } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import Phrase from "../components/Phrase";
import useAuth from "../hooks/useAuth";
import { Plus, Trash } from "@phosphor-icons/react";
import { ContentWithRight } from "../components/Flex";
import LoadableButton from "../components/LoadableButton";
import Skill from "../components/Skill";

const SkillsScreen = () => {
    const { currentSpace } = useAuth();
    useTitle(strings.skills, strings.skills);

    const [skillsAPI, callSkillsAPI] = useAPI();
    const history = useHistory();

    useEffect(() => {
        if (currentSpace) {
            callSkillsAPI("GET", `/api/spaces/${currentSpace.id}/skills`);
        }
    }, [currentSpace]);

    function removeSkill(skill) {
        skillsAPI.setResponse(skillsAPI.response.filter((s) => s.id !== skill.id));
    }

    return (
        <>
            <Button as={Link} to={`/spaces/${currentSpace.id}/topics/new`} icon={<Plus />} content={strings.add} />
            {skillsAPI.response?.map((skill) => (
                <Skill skill={skill} removeSkill={() => removeSkill(skill)} />
            ))}
        </>
    );
};

export default SkillsScreen;
