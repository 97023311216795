import { PencilSimpleLine } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { Checkbox, Divider, Form, Input } from "semantic-ui-react";
import { AudioSettings } from "../components/AudioSettings";
import CharacterSelector from "../components/CharacterSelector";
import { DeleteAccount } from "../components/DeleteAccount";
import { DeleteLanguage } from "../components/DeleteLanguage";
import LevelBar from "../components/LevelBar";
import Memory from "../components/Memory";
import { ModalInput } from "../components/ModalInput";
import SegmentWithHeader from "../components/SegmentWithHeader";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";

const UpdateName = ({ trigger }) => {
    const [userAPI, callUserAPI] = useAPI({ loading: false });
    const { user, setUser } = useAuth();
    const [name, setName] = useState();

    useEffect(() => {
        if (user) {
            setName(user.name);
        }
    }, [user]);

    function updateName() {
        setUser({ ...user, name: name });

        callUserAPI("PUT", "/api/user", { name: name });
    }

    return (
        <ModalInput
            label={strings.name}
            hasValue={name !== null}
            input={<Input value={name} onChange={(e) => setName(e.target.value)} style={{ width: "100%" }} />}
            trigger={trigger}
            onSave={updateName}
            saving={userAPI.loading}
            saveComplete={userAPI.response !== null && !userAPI.loading}></ModalInput>
    );
};

const YouSettings = () => {
    const { user, setUser, languageSettings, setLanguageSettings, currentSpace } = useAuth();

    useTitle(strings.settings_title, strings.settings);

    const [, callSpaceAPI] = useAPI();

    function updateEmailNotifications(subscribed) {
        user.subscribed_email_notifications = subscribed;
        setUser(user);

        callSpaceAPI("PUT", `/spaces/${currentSpace.id}/settings`, { subscribed_email_notifications: subscribed });
    }

    function updateLanguageSettings(languageSettings) {
        setLanguageSettings(languageSettings);
        callSpaceAPI("PUT", `/api/spaces/${currentSpace.id}/settings`, languageSettings);
    }

    return (
        <Form>
            {strings.formatString(strings.my_name_is_form, user.name)}{" "}
            <UpdateName trigger={<PencilSimpleLine />}></UpdateName>
            <Divider style={{ margin: "0.5rem" }} />
            <Form.Field>
                <p>
                    {strings.setting_daily_points_goal}:{" "}
                    <Input
                        value={languageSettings.daily_points_goal}
                        onChange={(e) =>
                            updateLanguageSettings({ ...languageSettings, daily_points_goal: e.target.value })
                        }
                        style={{ width: "4rem" }}
                    />
                </p>
            </Form.Field>
            <Form.Field>
                <p>
                    {strings.setting_character}{" "}
                    <CharacterSelector
                        selectedCharacterId={languageSettings.default_character_id}
                        setCharacterId={(id) => {
                            updateLanguageSettings({ ...languageSettings, default_character_id: id });
                        }}
                    />
                </p>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    toggle
                    label={strings.setting_enable_email_notifications}
                    checked={user.subscribed_email_notifications}
                    onChange={(e, { checked }) => updateEmailNotifications(checked)}></Checkbox>
            </Form.Field>
        </Form>
    );
};

export function SettingsScreen() {
    const { languageSettings, currentSpace, setCurrentSpace } = useAuth();
    const [spaceAPI, callSpaceAPI] = useAPI({ loading: false });
    const { setBackPath } = useTitle();

    useTitle(strings.settings_title, strings.settings);
    setBackPath(`/spaces/${currentSpace.id}/you`);

    useEffect(() => {
        callSpaceAPI("GET", `/api/spaces/${currentSpace.id}`);
    }, []);

    useEffect(() => {
        if (spaceAPI.response) {
            setCurrentSpace(spaceAPI.response);
        }
    }, [spaceAPI.response]);

    if (spaceAPI.loading || !languageSettings) {
        return <ZataLoader />;
    }

    return (
        <>
            <SegmentWithHeader header={<h3>{strings.you}</h3>}>
                <YouSettings />
            </SegmentWithHeader>
            <SegmentWithHeader header={<h3>{strings.memory}</h3>}>
                <Memory />
            </SegmentWithHeader>
            <SegmentWithHeader header={<h3>{strings.estimated_level_header}</h3>}>
                <LevelBar />
            </SegmentWithHeader>
            <SegmentWithHeader header={<h3>{strings.settings_audio}</h3>}>
                <AudioSettings />
            </SegmentWithHeader>
            <SegmentWithHeader header={<h3>{strings.delete_account}</h3>}>
                <DeleteAccount />
                <DeleteLanguage />
            </SegmentWithHeader>
        </>
    );
}
