import { useHistory } from "react-router-dom";
import { Dropdown, Label } from "semantic-ui-react";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import "./LanguageMenu.css";

const LanguageMenu = () => {
    const history = useHistory();
    const { currentSpace, user, switchSpace } = useAuth();

    const handleSpaceChange = (spaceId) => {
        switchSpace(spaceId);
        history.push(`/spaces/${spaceId}/home`);
    };

    return (
        <Dropdown trigger={<span>{currentSpace.name}</span>} pointing="top right" className={"language-menu"}>
            <Dropdown.Menu>
                {user.all_spaces.map((s) => (
                    <Dropdown.Item key={s.id} onClick={() => handleSpaceChange(s.id)}>
                        {s.name}
                    </Dropdown.Item>
                ))}
                <Dropdown.Item key={"start"} onClick={() => history.push(`/start`)}>
                    + {strings.add}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LanguageMenu;
