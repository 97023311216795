import { useEffect } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { TitleProviderContext } from "../providers/TitleProvider";
import { useLanguage } from "./useLanguage";
import useAuth from "./useAuth";

function useTitle(defaultPageTitle = "", defaultNavigationTitle = "", defaultDescription = null) {
    const {
        navigationTitle,
        image,
        setNavigationTitle,
        setImage,
        backPath,
        setBackPath,
        pageTitle,
        setPageTitle,
        description,
        setDescription,
    } = useContext(TitleProviderContext);

    const history = useHistory();

    const { currentSpace } = useAuth();

    const urlsWithBackButtons = [
        `/activities`,
        `/collections`,
        `/notebooks`,
        `/notes`,
        `/topics`,
        `/spaces/${currentSpace?.id}/questions`,
        `/spaces/${currentSpace?.id}/exercises`,
        `/spaces/${currentSpace?.id}/history`,
        `/spaces/${currentSpace?.id}/skills`,
        `/spaces/${currentSpace?.id}/plan`,
        `/spaces/${currentSpace?.id}/topics`,
        `/spaces/${currentSpace?.id}/notes`,
        `/spaces/${currentSpace?.id}/archive`,
        `/spaces/${currentSpace?.id}/settings`,
    ];

    useEffect(() => {
        updateBackPath();

        const unlisten = history.listen(updateBackPath);
        return () => {
            unlisten();
        };
    }, [history]);

    useEffect(() => {
        setPageTitle(defaultPageTitle);
    }, [defaultPageTitle, window.location.pathname]);

    useEffect(() => {
        setNavigationTitle(defaultNavigationTitle);
    }, [defaultNavigationTitle, window.location.pathname]);

    useEffect(() => {
        setDescription(defaultDescription);
    }, [defaultDescription, window.location.pathname]);

    useEffect(() => {
        if (pageTitle) {
            const titleWithJetway = pageTitle + " ✈️ Jetway";
            document.title = titleWithJetway;

            let meta = document.querySelector('meta[name="og:title"]');
            if (!meta) {
                meta = document.createElement("meta");
                meta.name = "og:title";
                document.head.appendChild(meta);
            }
            meta.content = titleWithJetway;
        }
    }, [pageTitle]);

    useEffect(() => {
        if (description) {
            // Set meta description
            let meta = document.querySelector('meta[name="description"]');
            if (!meta) {
                meta = document.createElement("meta");
                meta.name = "description";
                document.head.appendChild(meta);
            }
            meta.content = description;

            meta = document.querySelector('meta[name="og:description"]');
            if (!meta) {
                meta = document.createElement("meta");
                meta.name = "og:description";
                document.head.appendChild(meta);
            }
            meta.content = description;
        }
    }, [description]);

    useEffect(() => {
        if (image) {
            // Set meta image
            let meta = document.querySelector('meta[property="og:image"]');
            if (!meta) {
                meta = document.createElement("meta");
                meta.property = "og:image";
                document.head.appendChild(meta);
            }
            meta.content = image;
        }
    }, [image]);

    const updateBackPath = () => {
        let path = window.location.pathname;
        let backPathFound = false;
        for (let backPath of urlsWithBackButtons) {
            if (path.startsWith(backPath)) {
                setBackPath(`/spaces/${currentSpace.id}/home`);
                backPathFound = true;
                break;
            }
        }
        if (backPathFound === false) {
            setBackPath(null);
        }
    };

    setImage("/apple-touch-icon.png");

    return {
        navigationTitle,
        setNavigationTitle,
        image,
        setImage,
        backPath,
        pageTitle,
        setPageTitle,
        description,
        setDescription,
        setBackPath,
    };
}

export default useTitle;
