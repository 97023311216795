import React, { useState, useEffect } from "react";
import { Buffer } from "buffer";
import { useHistory, useParams } from "react-router-dom";
import { Button, Container, Dimmer, Header, Loader } from "semantic-ui-react";
import useAuth from "../hooks/useAuth";
import { useQuery } from "../hooks/useQuery";
import { useHash } from "../hooks/useHash";
import { strings } from "../utils/i18n.utils";
import Cookies from "js-cookie";
import { getBaseUrl } from "../utils/url.utils";

export function nextPage(user, redirectTo) {
    console.log("Login redirect", user, redirectTo);
    if (user.accepted_terms === false) {
        return `/terms?redirectTo=${encodeURIComponent(redirectTo)}`;
    } else if (user.current_space === null) {
        return `/start?redirectTo=${encodeURIComponent(redirectTo)}`;
    } else if (redirectTo && redirectTo !== "" && redirectTo !== "null" && !redirectTo.startsWith("/login")) {
        return redirectTo;
    } else {
        return `/spaces/${user.current_space.id}/home`;
    }
}

function extractFromURL(param, url) {
    const queryParams = new URLSearchParams(url.search);
    return queryParams.get(param);
}

export const Login = (props) => {
    const { setUser, setCookie, setCurrentSpace } = useAuth();
    const history = useHistory();
    const googleClientId = ["jetway.ai", "production.jetway.ai"].includes(window.location.hostname)
        ? "43221521360-v4a7gphcjj45aa8ik0p3m9mjujk45ne6.apps.googleusercontent.com"
        : window.location.hostname === "staging.jetway.ai" || window.Capacitor
        ? "738534842817-e93l5g61iu8p48vmac7d29d813bkf5li.apps.googleusercontent.com"
        : "813682741170-riabajse9fncu6fhmv4ie8f4mqfoqdl5.apps.googleusercontent.com";

    if (window.Capacitor) {
        console.log("Inside capacitor app");
        const loadPlugin = async () => {
            console.log("loading app plugin");
            const { App: AppPlugin } = await import("@capacitor/app");
            const { Browser } = await import("@capacitor/browser");
            AppPlugin.addListener("appUrlOpen", async (data) => {
                // Example url: myapp://profile/123?foo=bar
                console.log("App opened with URL:", data.url);
                const url = new URL(data.url);
                const jwt = extractFromURL("jwt", url);
                const base64Json = extractFromURL("json", url);
                const json = JSON.parse(Buffer.from(base64Json, "base64").toString("ascii"));
                // set the cookie
                Cookies.set("jwt_token", jwt);
                setCookie(jwt);
                document.cookie = `jwt_token=${jwt};path=/;`;
                setCurrentSpace(json.current_space);
                setUser(json);

                // close the browser after auth
                Browser.close();

                history.push(nextPage(json, ""));
            });
        };
        loadPlugin();
    }

    const query = useQuery();
    const buttonStyle = {
        minWidth: "260px",
    };

    function getState() {
        const fromCapacitor = !!window.Capacitor;
        let redirectTo = query.get("redirectTo");
        if (
            (!redirectTo || redirectTo === "") &&
            window.location.pathname !== "/" &&
            window.location.pathname !== "/login"
        ) {
            redirectTo = `${window.location.pathname}${window.location.search}`;
        }

        let data = { redirectTo: redirectTo, fromCapacitor: fromCapacitor };
        let jsonStr = JSON.stringify(data);
        return Buffer.from(jsonStr).toString("base64");
    }

    /**
     * Creates a function based on the specified social application.
     *
     * @param {"gmail"|"facebook"|"microsoft"} provider - The social application identifier.
     * @returns {() => void} A function that returns void.
     */
    function createAuthClickHandler(provider) {
        return async () => {
            let url = "";

            if (provider === "gmail") {
                const callbackUrl = `${getBaseUrl()}/login/google/callback`;
                const responseType = "permission%20id_token";
                url = `https://accounts.google.com/o/oauth2/auth?redirect_uri=${encodeURI(
                    callbackUrl
                )}&state=${getState()}&response_type=${responseType}&scope=email%20profile%20openid&openid.realm=&include_granted_scopes=true&client_id=${googleClientId}&ssmain=${
                    window.location.protocol
                }%3A%2F%2F${window.location.host}&prompt=&fetch_basic_profile=true&gsiwebsdk=2`;
            } else if (provider === "facebook") {
                const callbackUrl = `${getBaseUrl()}/login/facebook/callback`;
                url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=462462476123701&scope=email&redirect_uri=${callbackUrl}`;
            }

            if (window.Capacitor) {
                const { Browser } = await import("@capacitor/browser");
                Browser.open({
                    url: url,
                });
            } else {
                window.location = url;
            }
        };
    }

    return (
        <div>
            <div>
                <Button
                    onClick={createAuthClickHandler("gmail")}
                    icon="google"
                    primary
                    content={strings.login_with_google}
                    style={buttonStyle}
                />
            </div>
            <div style={{ margin: "1rem" }}></div>
            <div>
                <Button
                    onClick={createAuthClickHandler("facebook")}
                    icon="facebook"
                    primary
                    content={strings.login_with_facebook}
                    style={buttonStyle}
                />
            </div>
            <div style={{ margin: "1rem" }}></div>
            <div>
                <Button
                    as={"a"}
                    href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=ddb331f5-5c9b-43d1-980c-ba5d7daaa2d9&scope=openid%20profile%20email%20offline_access%20User.Read&response_type=code&redirect_uri=${
                        window.location.protocol + "//" + window.location.host + "/login/microsoft/callback"
                    }`}
                    icon="microsoft"
                    primary
                    content={"Login with Microsoft"}
                    style={buttonStyle}
                />
            </div>

            {props.showDiscord && (
                <>
                    <div style={{ margin: "1rem" }}></div>
                    <div>
                        <Button
                            icon="discord"
                            primary
                            style={buttonStyle}
                            content={strings.join_our_discord}
                            onClick={() => window.open("https://discord.gg/A3nSQEQZ6f")}></Button>
                    </div>
                </>
            )}
        </div>
    );
};

export const LoginCallback = () => {
    const history = useHistory();
    const { socialApp } = useParams();
    const hash = useHash();
    const query = useQuery();
    const [status, setStatus] = useState({ isLoading: true });
    const { setUser, setCurrentSpace } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let token = hash.get("id_token");

                if (token === null) {
                    token = query.get("code");
                }

                let state = hash.get("state");
                if (state === null) {
                    // WHYYYY
                    state = hash.get("#state");
                }

                let stateObj = {};
                if (state) {
                    stateObj = JSON.parse(Buffer.from(state, "base64").toString("ascii"));
                }

                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const response = await fetch(`${getBaseUrl()}/api/user/auth/${socialApp}`, {
                    method: "POST",
                    body: JSON.stringify({
                        token: token,
                        redirect_uri: window.location.protocol + "//" + window.location.host + window.location.pathname,
                        timezone: timezone,
                    }),
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 500) {
                    console.log(response);
                    setStatus({
                        isLoading: false,
                        isError: true,
                        error: "Internal error. Please email dan@jetway.ai to report this incident.",
                    });
                } else {
                    const json = await response.json();

                    if (stateObj.fromCapacitor) {
                        console.log("request from capacitor app");
                        const cookies = Cookies.get();
                        if (cookies.jwt_token) {
                            // redirect to capacitor with jwt
                            console.log("redirecting to app");
                            const jsonStr = JSON.stringify(json);
                            const base64Json = Buffer.from(jsonStr).toString("base64");

                            window.location.href = `jetway://jetway?jwt=${cookies.jwt_token}&json=${base64Json}`;
                            window.close();
                        }
                        return;
                    }
                    setCurrentSpace(json.current_space);
                    setUser(json);

                    history.push(nextPage(json, stateObj.redirectTo || ""));
                }
            } catch (error) {
                console.log(error);
                setStatus({ isLoading: false, isError: true, error: error });
            }
        };

        fetchData();
    }, []);

    if (status.isLoading) {
        return (
            <Container>
                <Dimmer active>
                    <Loader inverted>Logging in...</Loader>
                </Dimmer>
            </Container>
        );
    }

    return <></>;
};

export const LoginStandalone = () => {
    return (
        <div className="vertically-centered">
            <Header as="h3">{strings.login_or_create_account}</Header>
            <Login showDiscord={false} />
        </div>
    );
};
