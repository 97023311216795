import { Lightbulb, Plus } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { Segment } from "semantic-ui-react";
import { ActivitySkills } from "../components/ActivityDetails";
import { AddTabs } from "../components/AddTopic";
import EditableText from "../components/EditableText";
import LoadableButton from "../components/LoadableButton";
import { TextEditor, TextEditorSegment } from "../components/TextEditor";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import "./Note.css";
import Skill from "../components/Skill";

const NoteAnalysis = ({ note }) => {
    return (
        <div style={{ margin: "1rem 0" }}>
            {note?.analyze_task_id ? <p>{strings.notes_analyzing}</p> : <p>{strings.notes_found_skills}</p>}

            {!note.analyze_task_id && note.skills?.map((skill) => <Skill key={skill.id} skill={skill} />)}
        </div>
    );
};

const Note = ({ note: initialNote }) => {
    const [note, setNote] = useState(initialNote);
    const history = useHistory();
    const { setPageTitle, setNavigationTitle } = useTitle();
    const [addToStudyQueueAPI, callAddToStudyQueueAPI] = useAPI({ loading: false });
    const [analyzeNoteAPI, callAnalyzeNoteAPI] = useAPI({ loading: false });
    const { currentSpace } = useAuth();

    useEffect(() => {
        setNavigationTitle(strings.add);
    }, [currentSpace]);

    useEffect(() => {
        setPageTitle(note.title);
    }, [note.title]);

    function analyzeNote() {
        if (!note.id) {
            callAnalyzeNoteAPI("POST", `/api/spaces/${currentSpace.id}/notes?analyze=true`, note);
        } else {
            callAnalyzeNoteAPI("PUT", `/api/notes/${note.id}?analyze=true`, note);
        }
    }

    useEffect(() => {
        if (analyzeNoteAPI.response) {
            setNote(analyzeNoteAPI.response);
        }
    }, [analyzeNoteAPI.response]);

    function addToStudyQueue() {
        callAddToStudyQueueAPI("POST", `/api/notes/${note.id}/add_to_study_queue`);
    }

    useEffect(() => {
        if (addToStudyQueueAPI.response) {
            history.push(`/spaces/${currentSpace.id}/skills`);
        }
    }, [addToStudyQueueAPI.response]);

    return (
        <div>
            <AddTabs activeIndex={1} />
            <div className="note">
                <p>{strings.study_notes_description}</p>
                <TextEditorSegment lines={4}>
                    <TextEditor
                        key={note.id}
                        placeholder={"Write your note here..."}
                        onChange={(value) => {
                            if (value === note.text) {
                                return;
                            }
                            setNote({ ...note, text: value });
                        }}
                        initialText={note.text}
                    />
                </TextEditorSegment>
            </div>

            <LoadableButton
                icon={<Lightbulb />}
                content={strings.analyze}
                onClick={analyzeNote}
                disabled={analyzeNoteAPI.loading}
            />

            {analyzeNoteAPI.response && (
                <>
                    <NoteAnalysis note={note} analyzeNote={analyzeNote} analyzing={analyzeNoteAPI.loading} />

                    {note.skills?.length > 0 && (
                        <LoadableButton
                            icon={<Plus />}
                            content={"Add to Study Queue"}
                            onClick={addToStudyQueue}
                            disabled={addToStudyQueueAPI.loading}
                        />
                    )}
                </>
            )}
        </div>
    );
};

const NoteScreen = () => {
    const [noteAPI, callNoteAPI] = useAPI();
    const [note, setNote] = useState({ text: "" });
    const { noteId } = useParams();
    const { currentSpace } = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (noteId) {
            callNoteAPI("GET", `/api/notes/${noteId}`);
        }
    }, [noteId]);

    useEffect(() => {
        if (noteAPI.response && !noteId) {
            // Update the URL to reflect the new note ID without reloading the page
            history.replace(`/spaces/${currentSpace.id}/notes/${noteAPI.response.id}`);
        }
    }, [noteAPI.response]);

    if (noteAPI.loading) {
        return <ZataLoader />;
    }

    return <Note note={note} />;
};

export default NoteScreen;
