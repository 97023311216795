import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Topic } from "../components/Topic";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import useTitle from "../hooks/useTitle";

const TopicScreen = ({}) => {
    const { language, topicName } = useParams();
    const { currentSpace } = useAuth();
    const { nativeLanguage } = useLanguage();

    const [topicAPI, callTopicAPI] = useAPI();

    const { setPageTitle, setNavigationTitle } = useTitle();

    const reloadTopic = () => {
        callTopicAPI(
            "GET",
            `/api/topics/${language}/${topicName}?native_language=${currentSpace?.native_language || nativeLanguage}`
        );
    };

    useEffect(() => {
        reloadTopic();
    }, [language, topicName]);

    useEffect(() => {
        if (topicAPI.response) {
            setPageTitle(topicAPI.response.topic_native || topicAPI.response.topic);
            setNavigationTitle(topicAPI.response.topic_native || topicAPI.response.topic);
        }
    }, [topicAPI.response]);

    if (topicAPI.response === null || topicAPI.loading) {
        return <ZataLoader />;
    }

    return <Topic topic={topicAPI.response} reloadTopic={reloadTopic} />;
};

export default TopicScreen;
